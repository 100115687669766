import React, { useEffect } from "react";
import LayoutNew from "../../components/LayoutNew";
import { Helmet } from "react-helmet"; 
import ServiceAddress from "../new-pages/common/ServiceAddress";
import VideoSectionInternet from "../new-pages/common/VideoSectionInternet";
import HaveQuestion from "../new-pages/common/HaveQuestion";
import LastSection from "../new-pages/common/LastSection";
import MessageModal from "../new-pages/common/MessageModal";
import bubbleIcon from "../../../static/images/speech-bubble.png";
import Check from "../../static/images/checked-tick.svg";
import superIcon1 from "../../static/images/super-icon1.png";
import superIcon2 from "../../static/images/super-icon2.png";
import cowKissing from "../../static/images/cow-kissing.png";
import { calculateYearlyPrice } from "../../customfunction";
import RatingReviewInternet from "../new-pages/common/RatingReviewInternet";
import CommonQuestionWhenSettingUpYourInternet from "../new-pages/common/CommonQuestionWhenSettingUpYourInternet";
 

const InternetSetupPage = () => {

    const [messagePopup, setMessagePopup] = React.useState(false);  


    return (
        <LayoutNew>
            <Helmet>
                <title>Phone – Purple Cow Internet 💜🐄</title>
            </Helmet>

            <section className="tv-section1">
            <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">How to setup your internet</h2>
                </div>
            <div>
                    <iframe width="1120" height="630"  src="https://www.youtube.com/embed/m8-2Lq1MlgU?si=5uS2SEsGywqPqdXZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen  ></iframe>
            </div>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 1: Find the coax port in your home</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                        Your current modem might already be plugged into the coax port in your home. If that is the case, you can unplug your current modem and simply plug ours in. If this is not the case, locate the coax port in your home. It could be either a wall plate or a line coming through your wall. I should note that there could be several coax ports in your home, and maybe only one of them is live.
                    </p>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 2: Plug your modem into power</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                        Power time, baby! Feel free to plug your modem into any wall outlet, avoiding power strips. Give your modem about 15 minutes to set up. Assuming the coax port you are plugged into is live, you should see 5 lights lit on your modem. If for some reason you do not see all 5 lights please take a look at the trouble shooting tips below.                
                    </p>
            </section>
            <section className="tv-section1 max-width1000">
                <h2>Step 3: Text us "Password Please"</h2>
                <br></br>
                    <p className="container mx-auto px-4 text-secondary text-center">
                        Final step: Shoot us a text from your number on file to 902-800-2660 with the words 'Password Please,' and we will respond with your credentials to connect to your wifi. Go ahead, log in. You are now part of the herd! Thank you!                    
                    </p>
            </section>

            <CommonQuestionWhenSettingUpYourInternet />

            <HaveQuestion closepopup={setMessagePopup}/>
            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon" />
                </div>
            </div>
            {messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew >
    )
}

export default InternetSetupPage